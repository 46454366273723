import { createApp } from "vue";

import Swal from "sweetalert2";

import flatpickr from "flatpickr";
import { ZiggyVue } from "ziggy-js";
import DarkModeToggle from "@/components/darkModeToggle.vue";
import UsersList from "@/components/users/list.vue";
import CustomerList from "@/components/customers/list.vue";
import CustomerForm from "@/components/customers/form.vue";

window.Swal = Swal;

const components = {
  "dark-mode-toggle": DarkModeToggle,
  "users-list": UsersList,
  "customers-list": CustomerList,
  "customers-form": CustomerForm,
};

let n = 0;
for (const tag in components) {
  for (const el of document.querySelectorAll(tag)) {
    const app = createApp({
      name: `${tag} ${++n}`,
      template: el.outerHTML,
    });
    app.component(tag, components[tag]);
    app.mount(el);
    app.use(ZiggyVue);
  }
}

window.document
  .querySelector("#main-container")
  ?.addEventListener("click", (e) => {
    const button = e.target;
    if (e.target.classList.contains("js-confirmDelete")) {
      const deleteText = button.getAttribute("data-delete-text");

      confirmHandler(
        "Delete",
        deleteText,
        "Do you want to delete this?",
        "delete",
        button.getAttribute("data-delete-href"),
        "Deleted",
        button.getAttribute("data-delete-then"),
        true
      );
    }
  });
let confirmHandler = async function (
  confirmButton,
  confirmDetails,
  confirmQuestion,
  fetchAction,
  requestHref,
  successTitle,
  successEmit,
  isTable
) {
  const result = await Swal.fire({
    title: confirmQuestion,
    html: confirmDetails,
    icon: "question",
    showCancelButton: true,
    confirmButtonText: confirmButton,
    customClass: {
      confirmButton: "bg-danger",
    },
    reverseButtons: true,
    focusConfirm: false,
  });

  if (!result.isConfirmed) {
    return;
  }

  const response = await useFetch(requestHref, {
    method: fetchAction,
  });

  if (!response.ok) {
    alert("System error");
    criteria.loading = false;
  }

  const data = await response.json();

  if (data?.message.type === "error") {
    Swal.fire({
      icon: "error",
      title: "Error",
      text: data.message.title,
      customClass: {
        confirmButton: "bg-danger",
      },
      showConfirmButton: true,
    });
  } else {
    Swal.fire({
      icon: "success",
      title: successTitle,
      timer: 1500,
      timerProgressBar: true,
      showConfirmButton: false,
    }).then(() => {
      if (isTable === false) {
        location.reload();
      } else if (successEmit) {
        Eventbus.emit(successEmit);
      }
    });
  }
};

let tabButtons = document.querySelectorAll(".nav-link[data-bs-target]");
if (tabButtons.length) {
  for (let tabButton of tabButtons) {
    tabButton.addEventListener("show.bs.tab", function (ev) {
      window.location.hash = ev.target.getAttribute("data-bs-target");
    });

    // don't think I like this
    // if (tabButton.classList.contains('active')) {
    //     window.location.hash = tabButton.getAttribute('data-bs-target');
    // }
  }

  window.onpopstate = goToTabFromHash;
}
flatpickr(".js-flatpickers");
