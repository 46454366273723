<template>
  <div class="block block-rounded">
    <div class="block-header">
      <h3 v-if="formFields.id !== null" class="block-title">
        Update Customer - {{ customer.name }}
      </h3>
      <h3 v-else class="block-title">Create Customer</h3>
    </div>
    <div>
      <form id="customer" method="POST" @submit.prevent="createCustomer">
        <div v-if="formFields.id !== null">
          <input type="hidden" name="_method" value="PUT" />
        </div>
        <div class="block-content block-content-full">
          <div class="row push">
            <div class="col-lg-4">
              <p class="fs-sm text-muted">Company details</p>
              <div v-if="formFields.id == null" class="card p-4 bg-info-light">
                <h4>Search Companies House</h4>
                <div class="mb-4">
                  <label class="form-label">Company Name</label>
                  <input
                    v-model="companieshousesearch"
                    type="text"
                    class="form-control"
                  />
                </div>
                <div class="mb-4">
                  <div v-if="companyResults.length > 0">
                    <select
                      class="form-select"
                      @change="selectCompany($event.target.value)"
                    >
                      <option value="" selected>Select a company</option>
                      <option
                        v-for="(company, key) in companyResults"
                        :key="key"
                        :value="key"
                      >
                        {{ company.company_name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-8 col-xl-5">
              <div class="mb-4">
                <label class="form-label" for="name">Name</label>
                <input
                  id="name"
                  v-model="formFields.name"
                  type="text"
                  class="form-control"
                  name="name"
                  required
                />
              </div>
              <div class="mb-4">
                <label class="form-label" for="office_email">Email</label>
                <input
                  id="office_email"
                  v-model="formFields.office_email"
                  type="email"
                  class="form-control"
                  name="office_email"
                  required
                />
              </div>
              <div class="mb-4">
                <label class="form-label" for="office_phone">Phone</label>
                <input
                  id="office_phone"
                  v-model="formFields.office_phone"
                  type="text"
                  class="form-control"
                  name="office_phone"
                  required
                />
              </div>
              <div class="mb-4">
                <label class="form-label" for="office_address">Address</label>
                <input
                  id="office_address"
                  v-model="formFields.office_address"
                  type="text"
                  class="form-control"
                  name="office_address"
                  required
                />
              </div>
              <div class="mb-4">
                <label class="form-label" for="company_number">
                  Company Number
                </label>
                <input
                  id="company_number"
                  v-model="formFields.company_number"
                  type="text"
                  class="form-control"
                  name="company_number"
                  required
                />
              </div>
            </div>
          </div>
          <div class="row push">
            <div class="col-lg-4">
              <p class="fs-sm text-muted">Primary Contact Details</p>
            </div>
            <div class="col-lg-8 col-xl-5">
              <div class="mb-4">
                <label class="form-label" for="primary_contact_name">
                  Name
                </label>
                <input
                  id="primary_contact_name"
                  v-model="formFields.primary_contact.name"
                  type="text"
                  class="form-control"
                  name="primary_contact['name']"
                  required
                />
              </div>
              <div class="mb-4">
                <label class="form-label" for="primary_contact_email">
                  Email
                </label>
                <input
                  id="primary_contact_email"
                  v-model="formFields.primary_contact.email"
                  type="email"
                  class="form-control"
                  name="primary_contact['email']"
                  required
                />
              </div>
              <div class="mb-4">
                <label class="form-label" for="primary_contact_phone">
                  Phone
                </label>
                <input
                  id="primary_contact_phone"
                  v-model="formFields.primary_contact.phone"
                  type="text"
                  class="form-control"
                  name="primary_contact['phone']"
                  required
                />
              </div>
            </div>
          </div>
          <div class="row push">
            <div class="col-lg-4"></div>
            <div class="col-lg-8 col-xl-5">
              <div class="mb-4">
                <button type="submit" class="btn btn-alt-success">Save</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from "vue";
import debounce from "lodash/debounce";
import { useFetch } from "@/components/useFetch.js";

const props = defineProps({
  route: String,
  customer: {
    type: Object,
    default: () => ({
      id: undefined,
      name: undefined,
      office_address: undefined,
      office_email: undefined,
      office_phone: undefined,
      company_number: undefined,
      primary_contact: {
        name: undefined,
        email: undefined,
        phone: undefined,
      },
    }),
    required: false,
  },
});

const companieshousesearch = ref("");
const companyResults = ref([]);
const selectedCompany = ref(null);
const formFields = ref({
  id: props.customer.id ?? null,
  name: props.customer.name ?? null,
  office_address: props.customer.office_address ?? null,
  office_email: props.customer.office_email ?? null,
  office_phone: props.customer.office_phone ?? null,
  company_number: props.customer.company_number ?? null,
  primary_contact: {
    name: props.customer.primary_contact?.name ?? null,
    email: props.customer.primary_contact?.email ?? null,
    phone: props.customer.primary_contact?.phone ?? null,
  },
});

async function fetchCompanies() {
  const response = await useFetch(
    route("api.services.companies-house.search"),
    {
      method: "POST",
      body: { query: companieshousesearch.value },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  );

  const data = await response.json();

  companyResults.value = data.items;
}

function selectCompany(key) {
  selectedCompany.value = companyResults.value[key];
  formFields.value.company_number = selectedCompany.value.company_number;
  formFields.value.name = selectedCompany.value.company_name;
  formFields.value.office_address = Object.values(
    selectedCompany.value.registered_office_address
  ).join(", ");
}

async function createCustomer() {
  const customerResponse = await useFetch(route("api.customers.store"), {
    method: "POST",
    body: JSON.stringify(formFields.value),
    headers: {
      "Content-Type": "application/json",
    },
  });

  const customerData = await customerResponse.json();

  if (customerResponse.status === 201) {
    window.location.href = route("customers.show", {
      customer: customerData.id,
    });
  } else {
    await Swal.fire({
      icon: "error",
      title: "Error",
      text: "There was am error creating the customer",
    });
  }
}

watch(companieshousesearch, debounce(fetchCompanies, 500));
</script>
