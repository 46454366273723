<template>
  <div class="d-none d-lg-block">
    <div v-if="mode == 'initial'" class="text-center mt-4">
      <p v-if="wantsDarkMode">
        Dark Mode Enabled.
        <span @click="disable">Disable?</span>
      </p>
      <p v-else>
        Dark Mode Disabled.
        <span @click="enable">Enable?</span>
      </p>
    </div>
    <div v-else>
      <button
        v-if="wantsDarkMode"
        class="btn text-white rounded-pill"
        @click="disable"
      >
        <i class="far fa-lightbulb"></i>
      </button>
      <button v-else class="btn text-white rounded-pill" @click="enable">
        <i class="fas fa-lightbulb"></i>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    mode: {
      type: String,
      default: "toggle",
    },
  },

  data() {
    return {
      wantsDarkMode: false,
    };
  },

  mounted() {
    const cookies = document.cookie.split(";").map((cookie) => {
      const { _length, 0: name, 1: _value } = cookie.split("=");
      return name.trim();
    });

    const wantsDarkMode = cookies.includes("wants_dark_mode");
    const wantsLightMode = cookies.includes("wants_light_mode");

    if (wantsLightMode) {
      this.wantsDarkMode = false;
    } else if (wantsDarkMode) {
      this.wantsDarkMode = true;
    } else if (
      matchMedia &&
      matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      this.enable();
    }
  },

  methods: {
    enable() {
      this.deleteCookie("wants_light_mode");
      this.setCookie("wants_dark_mode");
      this.wantsDarkMode = true;
      this.toggleClass();
    },
    disable() {
      this.deleteCookie("wants_dark_mode");
      this.setCookie("wants_light_mode");
      this.wantsDarkMode = false;
      this.toggleClass();
    },

    deleteCookie(name) {
      document.cookie =
        name +
        "=checked;path=/;expires=" +
        new Date(Date.now() - 1000).toUTCString();
    },
    setCookie(name) {
      document.cookie =
        name +
        "=checked;path=/;expires=" +
        new Date(Date.now() + 365 * 24 * 60 * 60 * 1000).toUTCString();
    },

    toggleClass() {
      if (document.getElementById("page-container")) {
        document.getElementById("page-container").classList.toggle("dark-mode");
        document
          .getElementById("page-container")
          .classList.toggle("page-header-dark");
      }
    },
  },
};
</script>

<style scoped>
p {
  user-select: none;
}
span {
  cursor: pointer;
}
</style>
